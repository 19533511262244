
import { defineComponent } from 'vue';
import router from '@/router';
import { ElLoading } from 'element-plus';
import { pwdFreeLogin } from '@/api/common/pwdFreeLogin';
import { LoginResponse } from '@/views/base/login/all.type';

export default defineComponent({
    setup() {
        const loadingInstance = ElLoading.service();

        // 6.5.2免密登录需求：通过临时token获取登录token，用sessionStorage保存
        if (window.opener && window.opener.TmpToken) {
            pwdFreeLogin({
                TmpToken: window.opener.TmpToken
            }, [(res: LoginResponse) => {
                const msg = res.data;
                localStorage.setItem('grade', msg.grade);
                localStorage.setItem('communityID', msg.communityID);
                localStorage.setItem('peraccount', msg.perAccount);
                localStorage.setItem('initialization', msg.Initialization);
                sessionStorage.setItem('token', msg.token);
                sessionStorage.setItem('account', msg.account);
                sessionStorage.setItem('userName', msg.UserName);
                localStorage.removeItem('Community');
                loadingInstance.close();
                // 默认进入仪表盘页面，防止顶部下拉栏展示为single角色
                localStorage.setItem('InstallerLoginType', '1');
                router.push('/installerIndex/communityhome');
            }, false]);
        }
    }
});
